var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('title-row', {
    attrs: {
      "title": _vm.$route.name === 'NewBuilding' ? 'Új épület' : 'Épület szerkesztése'
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('title-row-button', {
          attrs: {
            "loading": _vm.loading
          },
          on: {
            "click": _vm.save
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-check")]), _vm._v(" Mentés ")], 1)];
      },
      proxy: true
    }])
  }), _vm.loading ? _c('loading-overlay') : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": 12,
      "lg": 6
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Alapadatok")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "Megnevezés",
      "error-messages": _vm.errors.name,
      "filled": ""
    },
    model: {
      value: _vm.form.building.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.building, "name", $$v);
      },
      expression: "form.building.name"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Típus",
      "error-messages": _vm.errors.type,
      "items": _vm.types,
      "item-text": "label",
      "item-value": "value",
      "filled": ""
    },
    model: {
      value: _vm.form.building.type,
      callback: function ($$v) {
        _vm.$set(_vm.form.building, "type", $$v);
      },
      expression: "form.building.type"
    }
  }), _vm.form.building.plan_file != '' ? _c('v-img', {
    attrs: {
      "src": _vm.form.plan_file_src,
      "height": "auto",
      "width": "200"
    }
  }) : _vm._e(), _vm.form.building.plan_file != '' ? _c('v-btn', {
    staticClass: "mb-8 mt-4",
    attrs: {
      "color": "secondary",
      "elevation": "0"
    },
    on: {
      "click": _vm.deleteUploadedFile
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-delete")]), _vm._v("Fájl törlése ")], 1) : _vm._e(), _c('v-progress-linear', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.file_uploading,
      expression: "file_uploading"
    }],
    staticClass: "mb-2",
    attrs: {
      "height": "8",
      "rounded": "",
      "value": _vm.file_uploading_percent
    }
  }), _c('v-file-input', {
    attrs: {
      "label": "Kép feltöltés",
      "show-size": "",
      "prepend-inner-icon": "mdi-camera",
      "prepend-icon": "",
      "accept": "image/*",
      "filled": "",
      "error-messages": _vm.errors.uploaded_file,
      "disabled": _vm.file_uploading,
      "hide-details": ""
    },
    model: {
      value: _vm.form.uploaded_file,
      callback: function ($$v) {
        _vm.$set(_vm.form, "uploaded_file", $$v);
      },
      expression: "form.uploaded_file"
    }
  }), _c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "disabled": _vm.file_uploading || _vm.form.uploaded_file == '' || _vm.form.uploaded_file == null || _vm.errors.uploaded_file != undefined,
      "color": "secondary",
      "elevation": "0",
      "loading": _vm.file_uploading
    },
    on: {
      "click": _vm.uploadFile
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-upload")]), _vm._v("Fájl feltöltése ")], 1)], 1)], 1)], 1), _c('v-col', [_c('v-card', [_c('v-card-title', [_vm._v("Szintek")]), _c('v-multi-select-list', {
    attrs: {
      "headers": [{
        text: 'Név',
        value: 'name'
      }],
      "item-value": "name",
      "item-text": "name",
      "blank-item-template": {
        name: ''
      }
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var item = _ref.item,
          index = _ref.index;
        return [_c('v-masked-text-field', {
          key: `level-${index}`,
          attrs: {
            "hide-details": "",
            "error-messages": _vm.errors[`plate-${index}`],
            "filled": "",
            "placeholder": "Név"
          },
          model: {
            value: item.name,
            callback: function ($$v) {
              _vm.$set(item, "name", $$v);
            },
            expression: "item.name"
          }
        })];
      }
    }], null, true),
    model: {
      value: _vm.form.building.levels,
      callback: function ($$v) {
        _vm.$set(_vm.form.building, "levels", $$v);
      },
      expression: "form.building.levels"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <title-row :title="$route.name === 'NewBuilding' ? 'Új épület' : 'Épület szerkesztése'">
      <template #buttons>
        <title-row-button @click="save" :loading="loading">
          <v-icon left>mdi-check</v-icon>
          Mentés
        </title-row-button>
      </template>
    </title-row>

    <loading-overlay v-if="loading" />

    <v-row>
      <v-col :cols="12" :lg="6">
        <v-card>
          <v-card-title>Alapadatok</v-card-title>
          <v-card-text>
            <v-text-field label="Megnevezés" :error-messages="errors.name" v-model="form.building.name" filled />
            <v-select label="Típus" :error-messages="errors.type" v-model="form.building.type" :items="types"
              item-text="label" item-value="value" filled />

            <v-img :src="form.plan_file_src" height="auto" width="200" v-if="form.building.plan_file != ''" />
            <v-btn v-if="form.building.plan_file != ''" @click="deleteUploadedFile" color="secondary" elevation="0"
              class="mb-8 mt-4">
              <v-icon left>mdi-delete</v-icon>Fájl törlése
            </v-btn>

            <v-progress-linear height="8" class="mb-2" rounded v-show="file_uploading"
              :value="file_uploading_percent"></v-progress-linear>

            <v-file-input label="Kép feltöltés" show-size v-model="form.uploaded_file" prepend-inner-icon="mdi-camera"
              prepend-icon="" accept="image/*" filled :error-messages="errors.uploaded_file" :disabled="file_uploading"
              hide-details />

            <v-btn :disabled="file_uploading ||
              form.uploaded_file == '' ||
              form.uploaded_file == null ||
              errors.uploaded_file != undefined
              " color="secondary" elevation="0" @click="uploadFile" :loading="file_uploading" class="mt-4">
              <v-icon left>mdi-upload</v-icon>Fájl feltöltése
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Szintek</v-card-title>
          <v-multi-select-list :headers="[{ text: 'Név', value: 'name' }]" v-model="form.building.levels" item-value="name" item-text="name"
            :blank-item-template="{ name: '' }">
            <template #[`item.name`]="{ item, index }">
              <v-masked-text-field v-model="item.name" hide-details :error-messages="errors[`plate-${index}`]" filled
                placeholder="Név" :key="`level-${index}`" />
            </template>
          </v-multi-select-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  components: {},

  data() {
    return {
      form: {
        uploaded_file: null,
        plan_file_src: '',
        building: {
          levels: [],
        },
      },
      types: [
        { value: 'B', label: 'Épület' },
        { value: 'P', label: 'Parkoló' },
      ],
      errors: {},
      file_uploading: false,
      file_uploading_percent: 0,
      loading: false,
    };
  },

  mounted() {
    this.fetchBuilding();
  },

  methods: {
    setPlanFileSrc() {
      this.form.plan_file_src =
        process.env.VUE_APP_API_URL +
        '../uploaded_files/' +
        this.form.building.plan_file +
        '?v=' +
        Math.floor(Math.random() * 1000000);
    },

    async fetchBuilding() {
      this.loading = true;
      try {
        const response = await this.$http.get(`buildings/details/${this.$route.params.id || 0}`);
        this.$set(this.form, 'building', response.building);
        this.setPlanFileSrc();
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },

    deleteUploadedFile() {
      this.form.building.plan_file = '';
      this.setPlanFileSrc();
    },

    uploadFile() {
      // TODO: test this and implement an async funciton instead
      const _this = this;

      this.errors = {};

      if (
        this.form.uploaded_file != '' &&
        this.form.uploaded_file != null &&
        ['image/jpg', 'image/jpeg'].indexOf(this.form.uploaded_file.type) < 0
      ) {
        this.errors.uploaded_file = 'Csak jpg fájl feltöltés engedélyezett';
        return true;
      }

      var form_data = new FormData();
      form_data.append('file', this.form.uploaded_file);
      this.file_uploading = true;

      this.$http
        .post('buildings/upload_file', form_data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress(progressEvent) {
            _this.file_uploading_percent = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
          },
        })
        .then((data) => {
          _this.file_uploading = false;
          _this.file_uploading_percent = 0;
          _this.form.building.plan_file = data.filename;
          _this.form.uploaded_file = null;
          _this.setPlanFileSrc();
        })
        .catch((error) => {
          _this.file_uploading = false;
          _this.file_uploading_percent = 0;
        });
    },

    async save() {
      this.loading = true;
      this.errors = {};

      try {
        const response = await this.$http.post('buildings/save', this.form.building);

        this.loading = false;

        if (Object.keys(response.errors).length) {
          return (this.errors = response.errors);
        }
        this.$dialog.notify.success('Sikeres mentés');
        this.$router.replace({ name: 'BuildingList' });
      } catch (e) {
        this.$dialog.notify.error(e);
      }
    },
  },
};
</script>
